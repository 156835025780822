import React from "react"
import {
  DropCareer,
  DropHeadingButton,
  DropHeadingTitle,
  ToggleH2Styled
} from "./dropStyle"

export const CareerLoad = () => {
  return (
    <DropCareer>
      <DropHeadingTitle>
        <ToggleH2Styled style={{ background: "#D0D0D0", height: "30px", width: "400px" }}/>
        <DropHeadingButton style={{ background: "#D0D0D0", height: "40px", width: "40px", borderRadius:"50%" }}/>
      </DropHeadingTitle>
    </DropCareer>
  )
}