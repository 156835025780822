import React, { useState, useEffect } from "react"
import CareerWrap from "./singleCareer"
import useTranslations from "../../components/useTranslations"
import SEO from "../../components/seo"
import { Col } from "antd"
import {
  CareerParagraphRow,
  CareerPageWrapper,
  H1Styled,
  PStyled,
} from "../../components/careercomponents/careerMainStyle"
import triple from "../../api/triple"
import { CareerLoad } from "../../components/careercomponents/careerDroping/careerLoad"
import SharedLogo from "../../assets/sharedLogo/share_logo(3c).png"

const Index = ({ pageContext }) => {

  const [careerdata, setCareerdata] = useState([])
  useEffect(()=>{
    triple.get('/api/join_our_team')
      .then(res =>{
        setCareerdata(res.data.data)
      } )
      .catch(err => console.log(err))
  }, [])

  const { career, careerForm } = useTranslations()

  return (
    <>
      <CareerPageWrapper>
        <SEO
          pageContext={pageContext}
        />
        <CareerParagraphRow justify="center">
          <Col>
            <H1Styled>{career.title}</H1Styled>
            <PStyled>{career.paragraph}</PStyled>
          </Col>
        </CareerParagraphRow>
        {careerdata.length > 0 ?
          careerdata.map((d, id) => (
            <>
              <CareerWrap
                setCareerdata={setCareerdata}
                careerdata={careerdata}
                singleCareer={d}
                seotitle={career.title}
                formlangtext={careerForm}
                seodescription={career.paragraph}
                pageContext={pageContext}
                careerForm={careerForm}
              />
            </>
          )):
          <CareerLoad />
        }
      </CareerPageWrapper>
    </>
  )
}

export default Index